/*========================================== MASTER STYLESHEET =================================================================== 
	Project : CHARITY TEMPLATE 
	Version : 1.0 
	Last Change : 25/04/2018 
	Primary Use : CHARITY TEMPLATE
=================================================================================================================================*/
/*========================================== LAYOUT ===================================================================
 1.GLOBAL STYLES 
 2.COMMON STYLES 
 3.NAVIGATION 
 4.BUTTON 
 5.READ MORE LINK 
 6.HEADER 
 8.HEADER FORM 
 9.SERVICES 
 10.CTA 
 11.FUND NEEDED 
 12.GALLERY 
 13.COUNTER 
 14.PRICE TABLE 
 15.TESTIMONIAL 
 16.NEWS 
 17.TEAM 
 18.SUBSCRIBE 
 19.FOOTER 
 20.MEDIA QUERIES
 =================================================================================================================================*/
/*========================================== COLOR CODES ========================================================================= 
	Background :#ffffff [white] 
	Main Heading Color :#333333 [black] 
	Content Color :#666666 [black] 
=================================================================================================================================*/
/*========================================== TYPOGRAPHY ========================================================================= 
	Heading:font-family: 'Roboto', serif;
	Body :font-family: 'Open Sans', sans-serif , Arial , Vedana , Trebuchet MS;
 =================================================================================================================================*/
/*========================================== 1.GLOBAL CSS START ===========================================*/
 @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
 @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
 body {
     background: #ffffff;
     color: #666666;
     font-family: 'Open Sans', sans-serif, Arial, Verdana, 'Trebuchet MS';
     font-size: 14px;
     font-weight: 400;
     line-height: 24px;
}
 a {
     outline: none !important;
     text-decoration: none !important;
}
 h1, h2, h3, h4, h5, p {
     margin: 0;
}
 h1, h2, h3, h4, h5 {
     color: #333333;
     font-family: 'Roboto', sans-serif;
     font-weight: 700;
     letter-spacing: 1px;
}
 h1 {
     font-size:50px;
     line-height: 55px;
}
 h2 {
     font-size: 35px;
     line-height: 40px;
}
 h3 {
     font-size: 28px;
     line-height: 33px;
}
 h4 {
     font-size: 20px;
     line-height: 25px;
}
 h5 {
     font-size: 16px;
     line-height: 21px;
}
 h6 {
     font-size: 15px;
     line-height: 20px;
}
 .text-center {
     text-align: center;
}
 .text-left {
     text-align: left;
}
 .text-right {
     text-align: right;
}
/*========================================== 1.GLOBAL CSS END ===========================================*/
/*========================================== 2.COMMON CSS START ===========================================*/
 .section-padding {
     padding-top: 80px;
     padding-bottom: 80px;
}
 .section-1-bg {
     background-color: #ffffff;
}
 .section-2-bg {
     background-color: #f2f2f2;
}
 .black-bg {
     background-color:#333333;
}
 .heading-div-1 {
     padding-bottom: 40px;
}
 .image-bottom-space {
     padding-bottom: 25px;
}
 .image-bottom-space20 {
     padding-bottom:20px;
}
 .image-bottom-space1 {
     padding-bottom: 15px;
}
 .image-top-space {
     padding-top: 25px;
}
 .content-top-space {
     padding-top: 15px;
}
 .content-top-space1 {
     padding-top: 10px;
}
 .btn-top-space1 {
     margin-top: 10px;
}
 .top-space {
     margin-top:30px;
}
 .top-space40 {
     margin-top:40px;
}
 .top-space20 {
     margin-top:20px;
}
 .top-space25 {
     margin-top:25px;
}
 .top-space15 {
     margin-top:15px;
}
 .top-space10 {
     margin-top:10px;
}
 .content-bottom-space {
     padding-bottom: 20px;
}
 .bgimage-property {
     background-position: center center;
     background-repeat: no-repeat;
     background-size: cover;
}
 .content-ul {
     padding-left: 0;
     margin-bottom: 0;
}
 .content-ul li {
     list-style: none;
}
 .underline {
     background-color: #ffffff;
     width: 60px;
     height: 2px;
     margin: 5px auto 0 auto;
}
 .underline-1 {
     background-color: #ffffff;
     width: 60px;
     height: 2px;
     margin: 5px 0 0 0;
}
 .list-div-left, .list-div-body {
     display: table-cell;
}
 .row-top-space {
     margin-top: 30px;
}
 .no-padding {
     padding:0;
}
/*========================================== 2.COMMON CSS END ===========================================*/
/*========================================== 3.NAVIGATION START ===========================================*/
/*common menu */
 .fixed-header {
     border-radius: 0;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     z-index: 99;
     box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
}
 .navbar-right {
     margin-right: 0;
}
 .navbar-default .navbar-nav > li > a:active, .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav.clearfix.fixed-header> li > a.active {
     transition: all 1.3s;
}
 .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .open > a {
     background-image: none;
     box-shadow:none;
}
 .navbar {
     border: none !important;
}
 .navbar-brand, .navbar-nav > li > a {
     text-shadow: none;
}
 .navbar-collapse {
     padding-left: 0;
     padding-right: 0;
}
 .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
     background-color: transparent;
}
 .logo-image{
     float: left;
     padding-bottom: 10px;
     padding-top: 10px;
}
 .topbar.navbar-default {
     background-color:#333333;
     border-radius:0;
     background-image: none !important;
     border: none !important;
     box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
}
 .navbar-nav.menu-ul {
     margin-top:25px;
}
 .navbar-nav.menu-ul > li.active > a.active, .navbar-nav.menu-ul > li > a.active, .topbar.navbar-default .navbar-nav > li > a:hover, .navbar-nav.menu-ul > li > a.active, .navbar-nav.menu-ul > .active > a, .navbar-nav.menu-ul > .active > a:hover, .navbar-default .navbar-nav.menu-ul > li > a:hover, .navbar-nav.menu-ul .pagescroll.active, .menu-ul .pagescroll:hover {
     color: #f0a830 !important;
}
 .navbar-nav.menu-ul > a:focus, .topbar.navbar-default .navbar-nav > li > a:focus {
     color: #ffffff ;
}
 .navbar-nav.menu-ul > li > a {
     color: #ffffff;
     font-size: 14px;
     font-weight: 600;
     letter-spacing: 0.5px;
     padding: 0 15px;
}
 .menu-div {
     margin:15px 0 15px 10px;
}
 .navbar-nav.menu-ul {
     margin:0;
}
 .navbar-nav.menu-ul > li.menu {
     margin:25px 0;
}
/*========================================== 3.NAVIGATION END ===========================================*/
/*========================================== 4.BUTTON START ===========================================*/
 .btn {
     border: none;
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
     cursor: pointer;
     font-size: 14px;
     font-weight: bold;
     letter-spacing: 1px;
     outline: none;
     padding: 10px 15px;
}
 .btn-top-space {
     margin-top: 25px;
}
 .btn1-div {
     position:relative;
     display:inline-block;
}
 .btn.btn-1 {
     background-color: #f0a830;
     color:#ffffff;
     -webkit-transition: all 0.7s ease;
     transition: all 0.7s ease;
}
 .btn-1:hover {
     background: transparent;
     color: #f0a830;
}
 .btn-1-white:hover:before, .btn-1-white:hover:after, .btn-1:hover:before, .btn-1:hover:after {
     -webkit-transition: all 0.7s ease;
     transition: all 0.7s ease;
     width: 100%;
}
 .btn-1:before, .btn-1:after {
     content: '';
     position: absolute;
     top: 0;
     left: 0px;
     width: 0px;
     height: 3px;
     background: #f0a830;
     -webkit-transition: all 0.4s ease;
     transition: all 0.4s ease;
}
 .btn-1:after {
     top: inherit;
     left: inherit;
     bottom: 0;
     right: 0;
}
 .btn-1-white {
     background-color: #ffffff;
     -webkit-transition: all 0.7s ease;
     transition: all 0.7s ease;
     color:#f0a830;
}
 .btn-1-white:hover {
     background: transparent;
     color: #ffffff;
}
 .btn-1-white:before, .btn-1-white:after {
     content: '';
     position: absolute;
     top: 0;
     left: 0px;
     width: 0px;
     height: 3px;
     background: #ffffff;
     -webkit-transition: all 0.4s ease;
     transition: all 0.4s ease;
}
 .btn-1-white:after {
     top: inherit;
     left: inherit;
     bottom: 0;
     right: 0;
}
/*========================================== 4.BUTTON END ===========================================*/
/*========================================== 5.READ MORE LINK START ===========================================*/
 .readmore {
     color: #f0a830;
     font-size: 14px;
     font-weight: 600;
     letter-spacing: 1px;
     padding-top: 10px;
}
 .readmore:hover {
     color: #f0a830;
}
 .readmore-white {
     color: #ffffff;
     font-size: 14px;
     font-weight: 600;
     letter-spacing: 1px;
     padding-top: 10px;
}
 .readmore-white:hover {
     color: #ffffff;
}
/*========================================== 5.READ MORE LINK END ===========================================*/
/*========================================== 6.HEADER START ===========================================*/
 /* .header-bgimage {
     background-image: url("../../images/1920x800x1.jpg");
} */
 .header-bgimage-slider-1 {
     background-image: url("../../images/slider-1.jpg");
}
 .header-bgimage-slider-2 {
     background-image: url("../../images/slider-2.jpg");
}
 .header-bgimage-slider-3 {
     background-image: url("../../images/slider-3.jpg");
}
 .heading-padding {
     padding-bottom:230px;
     padding-top: 230px;
}
 .header-bgimage-slider-1 .header-div {
     margin:0 auto;
     /* width:80%; */
}
 .header-bgimage-slider-1 .header-div h1 {
     color: #ffffff;
     font-size:55px;
     font-weight:700;
     letter-spacing:1px;
     line-height:65px;
}
 .header-bgimage-slider-1 .header-div p{
     color:#ffffff;
     font-weight:600;
     margin:0 auto;
     width:80%;
}
.header-bgimage-slider-2 .header-div {
    margin:20px auto;
    width:80%;
}
.header-bgimage-slider-2 .header-div h1 {
    color: #333333;
    font-size:55px;
    font-weight:700;
    letter-spacing:1px;
    line-height:65px;
}
.header-bgimage-slider-2 .header-div p{
    color:#666666;
    font-weight:600;
    width:80%;
}
.header-bgimage-slider-3 .header-div {
    margin:32px auto;
    width:80%;
}
.header-bgimage-slider-3 .header-div h1 {
    color: #333333;
    font-size:55px;
    font-weight:700;
    letter-spacing:1px;
    line-height:65px;
}
.header-bgimage-slider-3 .header-div p{
    color:#666666;
    font-weight:600;
    margin-left: auto;
    width:80%;
}
 .header-slider.owl-theme .owl-controls .owl-page span {
     background-color:#eb593b;
}
 .header-slider.owl-theme .owl-controls {
     text-align: center;
     position: absolute;
     z-index: 1;
     left: 0;
     right: 0;
     margin: 0 auto;
     bottom: 10px;
}
/*========================================== 6.HEADER END ===========================================*/
/*========================================== 7.HEADER FORM START ===========================================*/
 .book-now-form {
     background-color:#f0a830;
     box-shadow: 0 19px 38px rgba(0, 0, 0, 0.05), 0 -19px 38px rgba(0, 0, 0, 0.16);
     padding:30px 30px;
}
 .form-head h3 {
     color:#ffffff;
     margin-top:20px;
}
 .form-head {
     background-color:#333333;
     padding:30px 20px;
}
 .book-form .form-input {
     color: #ffffff;
     border:1px solid #ffffff;
     background-color:transparent;
     border-radius: 0px;
     height: 40px;
     padding-left: 20px;
     padding-right: 20px;
     position:relative;
     width:100%;
}
 .book-form .form-div label{
     color:#cc0000;
     font-weight:600;
}
 .book-form .failMessage {
     color:#cc0000;
}
 .book-form .form-div {
     margin-bottom:20px;
}
 .book-form .form-textarea {
     color: #ffffff;
     border:1px solid #ffffff;
     background-color:transparent;
     border-radius: 0px;
     padding-top:10px;
     padding-left: 20px;
     padding-right: 20px;
     position:relative;
     width:100%;
}
 .form-textarea::-webkit-input-placeholder, .form-input::-webkit-input-placeholder {
    /* Chrome */
     color: #ffffff;
}
 .form-textarea:-ms-input-placeholder, .form-input:-ms-input-placeholder {
    /* IE 10+ */
     color: #ffffff;
}
 .form-textarea::-moz-placeholder, .form-input::-moz-placeholder {
    /* Firefox 19+ */
     color: #ffffff;
     opacity: 1;
}
 .form-textarea:-moz-placeholder, .form-input:-moz-placeholder {
    /* Firefox 4 - 18 */
     color: #ffffff;
     opacity: 1;
}
/*========================================== 8.HEADER FORM ENDS ===========================================*/
/*========================================== 9.SERVICES START ===========================================*/
 .services-bg-4, .services-bg-1 {
     background-color:#f0a830;
     -webkit-transition: all 0.7s ease;
     transition: all 0.7s ease;
}
 .services-bg-2 {
     background-color:#eb593b;
     -webkit-transition: all 0.7s ease;
     transition: all 0.7s ease;
}
 .services-bg-3 {
     background-color:#333333;
     -webkit-transition: all 0.7s ease;
     transition: all 0.7s ease;
}
 .services-div {
     padding:80px 15px;
}
 .services-bg-4 p, .services-bg-4 h4, .services-bg-1 p, .services-bg-1 h4 {
     color:#ffffff;
}
 .services-bg-2 p, .services-bg-2 h4 {
     color:#ffffff;
}
 .services-bg-3 p, .services-bg-3 h4 {
     color:#ffffff;
}
 .services-main .containter-fluid {
     padding:0;
}
 .services-main .services-col{
     padding:0;
}
 .services-bg-1:hover {
     background-image: url("../../images/380x450x1.jpg");
}
 .services-bg-2:hover {
     background-image: url("../../images/380x450x2.jpg");
}
 .services-bg-3:hover {
     background-image: url("../../images/380x450x3.jpg");
}
 .services-bg-4:hover {
     background-image: url("../../images/380x450x4.jpg");
}
 .causes-col img {
     border-bottom:5px solid #f0a830;
}
 .pie-title-center {
     bottom: -35px;
     left: 0;
     position: absolute;
     right: 0;
     text-align: center;
}
 .pie-title-center canvas {
     width:60px;
     height:60px;
     line-height:60px;
     background-color:#f0a830;
     border-radius:50%;
}
 .pie-value {
     color:#ffffff;
     font-size:13px;
     font-weight:600;
     display: block;
     height: 40px;
     left: 0;
     line-height: 40px;
     margin-top: -25px;
     position: absolute;
     right: 0;
     top: 50%;
}
 .causes-img {
     position:relative;
}
 .causes-goal {
     margin-top:40px;
}
 .fund-goal li, .causes-goal li {
     display:inline-block;
}
 .fund-goal li, .causes-goal li {
     font-weight:600;
     letter-spacing:1px;
}
 .fund-goal li {
     color:#ffffff;
}
 .fund-goal li:first-child, .causes-goal li:first-child {
     margin-right:20px;
}
 .causes-goal li:first-child span {
     color:#999999;
}
 .causes-goal li:last-child span {
     color:#f0a830;
}
 .fund-goal li:first-child span {
     color:#f2f2f2;
}
 .fund-goal li:last-child span {
     color:#f0a830;
}
 .fund-goal {
     background-color:#444444;
     padding:10px 10px;
}
 .causes-div {
     box-shadow:0 12px 15px -6px rgba(0,0,0,0.3);
}
 .causes-content {
     padding:0 15px 30px 15px;
}
 .fund-div {
     background-color:#eb593b;
}
 .fund-content {
     padding:0 15px 30px 15px;
}
 .fund-div p{
     color:#ffffff;
}
 .fund-div h4 {
     background-color:#333333;
     color:#ffffff;
     padding:10px 15px;
}
 .about4-div img {
     padding-right:10px;
     vertical-align:middle 
}
 .list-img {
     float:left;
     width:65px;
}
 .list-img, .list-content {
     display:table-cell;
}
 .two-left-bgimage {
     background-image: url("../../images/1000x730x1.jpg");
     height: 695px;
     width: 50%;
}
 .two-left-div {
     padding:80px 30px 80px 30px;
}
 .two-left-div h3 a {
     color: #333333;
}
 .two-left-bgimage-1 {
     background-image: url("../../images/1000x730x2.jpg");
     height: 594px;
     position:absolute;
     right:0;
     width: 50%;
}
 .two-left-div-1{
     padding:80px 30px 80px 0px;
}
 .two-left-div-1 h3 a {
     color: #333333;
}
 .two-left-right-bg {
     position:relative;
}
 .three-col-div {
     box-shadow:0 12px 18px -6px rgba(0,0,0,0.4);
     -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     -ms-transition: all 0.5s ease;
     transition: all 0.5s ease;
}
 .three-col-div:hover {
     box-shadow:0 0px 9px -6px rgba(0,0,0,0.4);
}
 .three-col-content {
     padding:0 15px 30px 15px;
}
 .about-points-1 li {
     float:left;
     width:50%;
}
 .features-div-1 .list-img {
     display: inline-block;
     margin-right:20px;
     position:relative;
     width:64px;
}
 .features-div-1 .list-img img {
     border-radius:50%;
}
 .features-div-1 .list-img:after {
     box-shadow: 0 0 0 3px #eb593b;
     box-sizing: content-box;
     border-radius:50%;
     content: "";
     height: 100%;
     left: -4px;
     opacity: 0;
     padding: 4px;
     pointer-events: none;
     position: absolute;
     top: -4px;
     transform: scale(0.8);
     transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
     width: 100%;
}
 .features-col:hover .features-div-1 .list-img:after {
     opacity: 1;
     transform: scale(1);
}
/*popup*/
/* lightbox popup */
 #fade{
     display: none;
     position: fixed;
     top: 0%;
     left: 0%;
     width: 100%;
     height: 100%;
     background-color: #000;
     z-index: 1001;
     -moz-opacity: 0.7;
     opacity: .70;
     filter: alpha(opacity=70);
}
 #success_btn, #cancel_btn{
     background-color:#ffffff;
     box-shadow:0 12px 18px -6px rgba(0,0,0,0.7);
     display: none;
     position: fixed;
     border-radius: 5px;
     padding: 7px 10px 30px 10px;
     margin:0 auto;
     z-index: 99999;
     width: 35%;
     top:30%;
     left:0;
     right:0;
     text-align:center;
}
 .cancel-head-pad{
    padding:30px 10px 15px 10px;
}
 .popup-bg {
     background-image: url("../../images/600x450.jpg");
     width:40%;
}
 .popup-bg input{
     background-color:rgba(255,255,255,0.3);
     border:0;
     color:#ffffff;
     height:40px;
     padding-left:15px;
     padding-right:15px;
     width:100%;
}
 .popup-bg select{
     background-color:rgba(255,255,255,0.3);
     border:0;
     color:#ffffff;
     height:40px;
     padding-left:15px;
     width:100%;
}
.popup-bg option {
	color:#333333 !important;
}
 .p-white {
     color:#ffffff;
     font-weight:600;
     margin-top:28px;
}
 .popup-bg h2 {
     color:#ffffff;
}
 .popup-form {
     margin:0 auto;
     padding-bottom:50px;
     width:85%;
}
 .popup-bg .close {
     color:#ffffff;
     opacity:1;
}
 .close {
     opacity:1;
}
 .close:hover {
     opacity:1;
}
 .popup-bg label {
     display:none !important;
}
 .popup-bg select.error, .popup-bg input.error {
     background-color:rgba(255, 0, 0,0.4) !important;
}





.popup-bg input::-webkit-input-placeholder {
     color: #ffffff;
     text-transform: none;
}
 .popup-bg input::-moz-placeholder {
    color: #ffffff;
     text-transform: none;
	 opacity:1;
}

 .popup-bg input::-ms-input-placeholder {
    color: #ffffff;
     text-transform: none;
}
 .popup-bg input:-ms-input-placeholder {
    color: #ffffff !important;
     text-transform: none;
}



/*========================================== 9.SERVICES ENDS ===========================================*/
/*========================================== 10.CTA START ===========================================*/
 .cta-bgimage {
     background-image: url("../../images/1920x700x1.jpg");
     padding:180px 0;
}
 .cta-div {
     margin:0 auto;
     width:80%;
}
 .cta-div h1 {
     color: #ffffff;
     font-size:55px;
     font-weight:700;
     letter-spacing:1px;
     line-height:65px;
}
 .cta-div p{
     color:#ffffff;
     font-weight:600;
     margin:0 auto;
     width:80%;
}
 .client-logo .item {
     margin:0 auto;
     text-align:center;
}
/*========================================== 10.CTA ENDS ===========================================*/
/*========================================== 11.FUND NEEDED START ===========================================*/
 .fund-slider.owl-carousel.owl-theme .owl-controls .owl-pagination {
     display: none;
}
 .fund-slider.owl-theme .owl-controls .owl-buttons div {
     background: #f0a830;
     border-radius: 0;
     margin: 0 3px;
     opacity: 1;
     padding: 3px 5px;
}
 .fund-slider.owl-theme .owl-controls .owl-buttons {
     position: absolute;
     right: 0;
     top: -50px;
}
 .fund-slider .icon-left:before {
     color: #ffffff;
     content: "\f053";
     font-family: Fontawesome;
     font-size: 12px;
}
 .fund-slider .icon-right:before {
     color: #ffffff;
     content: "\f054";
     font-family: Fontawesome;
     font-size: 12px;
}
 .fund-slider {
     margin-top:40px;
}
 .fund-slider .item {
     margin-left:10px !important;
     margin-right:10px !important;
}
/*========================================== 11.FUND NEEDED START ===========================================*/
/*========================================== 12.GALLERY START ===========================================*/
 .gallery-div .gallery-image {
     position: relative;
     overflow: hidden;
}
 .gallery-div .overlay {
     background: rgba(235, 89, 59, 0.6);
     position: absolute;
     margin: auto;
     width: 0px;
     height: 0px;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
     opacity: 0;
     -webkit-transition: .4s ease;
     transition: .4s ease;
}
 .gallery-div .gallery-image span {
     font-size: 25px;
     color: #fff;
     text-align: center;
     position: absolute;
     margin: auto;
     height: 30px;
     line-height: 30px;
     left: 0;
     top: 0;
     right: 0;
     bottom: 0;
     opacity: 0;
     -webkit-transform: scale(0);
     transform: scale(0);
     -webkit-transition: transform .5s ease, opacity .7s ease;
     transition: transform .5s ease, opacity .7s ease;
}
 .gallery-div .gallery-image:hover .overlay {
     opacity: 1;
     width:100%;
     height:100%;
}
 .gallery-div .gallery-image:hover span {
     opacity: 1;
     -webkit-transform: scale(1);
     transform: scale(1);
     -webkit-transition: transform .5s ease, opacity 1.3s ease;
     transition: transform .5s ease, opacity 1.3s ease;
}
 .gallery-div .gallery-image img {
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
}
 .gallery-div.popup-gallery .col-sm-4{
     padding-left:2px;
     padding-right:2px;
}
 .gallery-div.popup-gallery .gallery-space1 {
     padding-top:4px;
}
/*========================================== 12.GALLERY ENDS ===========================================*/
/*========================================== 13.COUNTER START ===========================================*/
 .counter-bgimage {
     background-image: url("../../images/1920x700x2.jpg");
     padding:150px 0;
}
 .counter-div img {
     border-radius:50%;
}
 .counter-value {
     color:#ffffff;
     font-size:50px;
     font-weight:600;
     letter-spacing:1px;
     line-height:55px;
     margin-top:20px;
}
 .counter-title {
     color:#ffffff;
     font-weight:600;
     letter-spacing:1px;
}
 .counter-img {
     display: inline-block;
     position:relative;
}
 .counter-img:after {
     box-shadow: 0 0 0 3px #eb593b;
     box-sizing: content-box;
     border-radius:50%;
     content: "";
     height: 100%;
     left: -4px;
     opacity: 0;
     padding: 4px;
     pointer-events: none;
     position: absolute;
     top: -4px;
     transform: scale(0.8);
     transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
     width: 100%;
}
 .counter-col:hover .counter-img:after {
     opacity: 1;
     transform: scale(1);
}
/*========================================== 13.COUNTER ENDS ===========================================*/
/*========================================== 14.PRICE TABLE START ===========================================*/
 .packages-col-1 {
     position:relative;
     padding:30px 50px;
}
 /*.packages-col-1:before {
     background-color: #ffffff;
    /* border-radius: 50% 50% 0;
    */
  /*   content: "";
     display: block;
     height: 100%;
     left: 0;
     margin: 0 auto;
     position: absolute;
     right: 0;
     text-align: center;
     top: 0;
     -webkit-transform: rotate(45deg);
     transform: rotate(45deg);
     width: 100%;
}*/
 .packages-border, .packages-price {
     position:relative;
     z-index:2;
}
 .packages-main {
     padding:80px 0 80px 0;
}
 .packages-price p {
     color:#333333;
     font-size:40px;
     font-weight:600;
}
 .packages-price p span {
     font-size:20px;
}
.packages-bg:before {
     background-color:#eb593b;
     z-index:1;
}
 .packages-bg .packages-price p{
     color:#ffffff;
}
 .packages-bg .packages-content p {
     color:#ffffff;
}
 .packages-bg h4 {
     color:#ffffff;
}
 .packages-col-1 {
     -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     -ms-transition: all 0.5s ease;
     transition: all 0.5s ease;
}
 .packages-col-1:hover {
     -webkit-transform:scale(1.1);
     transform:scale(1.1);
     z-index:2;
}

.packages-bg-1 {
	background-color:#ffffff;
}

.packages-bg {
	background-color:#eb593b;
}

 .clients-bgimage {
     background-image: url("../../images/1920x700x5.jpg");
}

.map-frame {
    border: 0 none;
    height: 562px;
    width: 100%;
}
/*========================================== 14.PRICE TABLE ENDS ===========================================*/
/*========================================== 15.TESTIMONIAL START ===========================================*/
 .testimonial .item{
     margin-left:15px !important;
     margin-right:15px !important;
     margin-bottom:10px;
}
 .testimonial.owl-theme .owl-controls .owl-page span {
     background-color:#f0a830;
     border-radius:0;
}
 .testimonial .owl-pagination {
     margin-top:30px;
}
 .testimonial .testimonial-div {
     border:1px solid #f0a830;
     padding:120px 30px 50px 30px;
}
 .testimonial .testimonial-div > img {
     position:absolute;
     top:0;
     margin:0 auto;
     left:0;
     right:0;
}
 .testimonial .testimonial-name {
     color:#f2f2f2;
     font-size:18px;
     font-weight:600;
     margin-bottom:10px;
     margin-top:15px;
     letter-spacing:0.5px;
}
 .testimonial .testimonial-name span {
     color:#f0a830;
     font-size:12px;
     font-weight:600;
     padding-left:5px;
     letter-spacing:0.5px;
}
 .testimonial-bg {
     background-image: url("../../images/1920x700x4.jpg");
}
 .testimonial-para {
     color:#eeeeee;
}
 .testimonial-bg h2 {
     color:#ffffff;
}
 .testimonial {
     padding-top:30px;
}
/*========================================== 15.TESTIMONIAL ENDS ===========================================*/
/*========================================== 16.NEWS START ===========================================*/
 .news-ul li:nth-child(2) {
     background-color:#eb593b;
     padding:10px 10px;
     width:70px;
}
 .news-ul li:nth-child(2) p a{
     color:#ffffff;
     font-size:13px;
     font-weight:600;
     text-align:center;
}
 .news-ul li:nth-child(2) p span {
     font-size:30px;
}
 .news-div-1 .list-img {
     box-shadow:0 12px 18px -6px rgba(0,0,0,0.4);
     -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     -ms-transition: all 0.5s ease;
     transition: all 0.5s ease;
     width:70px;
}
 .news-div-1 .list-content{
     padding-left:20px;
}
 .news-col h4 a {
     color: #333333;
}
 .news-col:hover .list-img {
     box-shadow:0 0px 9px -6px rgba(0,0,0,0.4);
}
/*========================================== 16.NEWS ENDS ===========================================*/
/*========================================== 17.TEAM START ===========================================*/
 .team-carousel {
     padding: 0 40px 0px 40px;
}
 .team-carousel .owl-pagination {
     display:none;
}
 .team-carousel .carousel-control.left:before {
     color:#f0a830;
     left: 0;
     background-image: none;
     background: none repeat scroll 0 0 #ffffff;
     border: 4px solid #f0a830;
     border-radius: 23px 23px 23px 23px;
     height: 40px;
     width : 40px;
     content: "\f053";
     font-family: Fontawesome;
     margin-top: 30px;
     position:absolute;
     line-height: 35px;
     font-size: 12px;
     line-height:34px;
     top:50px;
}
 .team-carousel .carousel-control.right:before {
     color:#f0a830;
     right: -12px !important;
     background-image: none;
     background: none repeat scroll 0 0 #ffffff;
     border: 4px solid #f0a830;
     border-radius: 23px 23px 23px 23px;
     height: 40px;
     width : 40px;
     content: "\f054";
     font-family: Fontawesome;
     margin-top: 30px;
     position:absolute;
     line-height: 35px;
     font-size: 12px;
     padding-left:3px;
     line-height:34px;
     top:50px;
}
 .team-carousel.owl-theme .owl-controls .owl-buttons div {
     background-color:transparent;
}
 .team-carousel .carousel-indicators {
     right: 50%;
     top: auto;
     bottom: 0px;
     margin-right: -19px;
}
 .team-carousel .carousel-indicators li {
     background: #c0c0c0;
}
 .team-carousel .carousel-indicators .active {
     background: #333333;
}
 .team_profile_circle img {
     border-radius:50%;
     width: 170px;
     height: 170px;
     position:absolute;
     top:0px;
     left:0px;
}
 .team-row .team_main_circle {
     background: #f2f2f2 none repeat scroll 0 0;
     border: 5px solid #f0a830;
     border-radius: 50%;
     height: 300px;
     width: 300px;
     display: table;
     margin:0 auto;
     position: relative;
}
 .team-row .team_main_circle .team_main_circle_contain {
     display: table-cell;
     vertical-align: middle;
     font-size: 18px;
     text-align: center;
     font-weight: 600;
     letter-spacing:0.5px;
}
 .team-row .team_main_circle .team_main_circle_contain p:nth-child(2){
     color:#333333;
     font-size: 14px;
     letter-spacing:0.5px;
}
 .team-row .team_main_circle .team_main_circle_contain p {
     color:#eb593b;
	margin:0 auto;
	width:120px;
}
 .team-row .team_main_circle .team_profile_circle {
     border: 5px solid #f0a830;
     border-radius: 50%;
     height: 180px;
     left: 200px;
     position: absolute;
     top: -25px;
     width: 180px;
}
 .padtop30 {
     padding-top: 90px;
     padding-bottom:50px;
}
 .team-icon li {
     display:inline-block;
     margin:3px;
}
 .team-bg {
    background-color: #333333;
     /* background-image: url("../../images/1920x700x3.jpg"); */
}
 .team-bg h2 {
     color:#ffffff;
}
 .team_main_circle:hover .team_profile_circle{
     -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     -ms-transition: all 0.5s ease;
     transition: all 0.5s ease;
     top: -70px;
}
/*========================================== 17.TEAM ENDS ===========================================*/
/*========================================== 18.SUBSCRIBE START ===========================================*/
 #mc_embed_signup {
     clear: left;
}
 #mc-embedded-subscribe {
     margin: 0 0 0em 0 !important;
}
 #ResultMsg {
     text-align: center;
}
 .SuccessMsg {
     color: #33cc33;
}
 .FailureMsg {
     color: #ff0000;
}
 .messageDiv {
     display:table;
     text-align: center;
     margin-top:10px;
     width: 100%;
}
 .SuccessMsg, .FailureMsg, .sucessMessage, .failMessage {
     font-size: 13px;
     font-weight: bold;
     display: none;
     margin-top: 10px;
}
 .sucessMessage {
     color: Green;
}
 .failMessage {
     color: #ff0000;
}
 .message > .SuccessMsg {
     color:#008000 ;
}
 .place_error::-webkit-input-placeholder {
     color: #ff0000;
     text-transform: none;
}
 .place_error::-moz-placeholder {
     color: #ff0000;
     text-transform: none;
}
 .place_error::-moz-placeholder {
     color: #ff0000;
     text-transform: none;
}
 .place_error::-ms-input-placeholder {
     color: #ff0000;
     text-transform: none;
}
 .place_error:-ms-input-placeholder {
     color: #ff0000 !important;
     text-transform: none;
}
 .subscribe-form .subscribe-input {
     background-color:transparent;
     border:1px solid #eeeeee;
     border-radius:3px;
     color:#ffffff;
     display:inline-block;
     height:35px;
     max-width:100%;
     padding:0 15px;
     margin-bottom:20px;
     width:100%;
}
 .subscribe-form .subscribe-form {
     margin:30px auto 0 auto;
}
 .subscribe2-btn {
     display:inline-block;
}
/*========================================== 18.SUBSCRIBE ENDS ===========================================*/
/*========================================== 19.FOOTER START ===========================================*/
 .footer-row .footer1-col .links li a {
     color: #eeeeee;
     letter-spacing:0.5px;
     line-height: 30px;
}
 .footer-row .footer1-col .links li {
     border-bottom:1px solid #666666;
     margin-top:5px;
     padding-bottom:5px;
     position:relative;
     list-style: none;
}
 .footer-row .footer1-col .links li:before {
     color: #ffffff;
     content: "\f105";
     font-family: Fontawesome;
     font-size: 14px;
     padding-right:15px;
}
 .footer-row .post-img {
     display: table-cell;
     float: left;
     overflow-x: hidden;
     overflow-y: hidden;
     padding-right: 15px;
     opacity: 1;
}
 .footer-row .post {
     display: table-cell;
}
 .footer-row .post p a {
     color: #eeeeee;
     font-weight:600;
     letter-spacing:0.5px;
}
 .footer-row .footer-date ul {
     display: inline-block;
}
 .footer-row .footer-date-ul li {
     display: inline-block;
     vertical-align: middle;
}
 .footer-row .footer-date-ul li:first-child {
     padding-right: 5px;
}
 .footer-row .footer-date-ul-1 {
     padding-bottom: 10px;
}
 .footer-row .popular-space {
     margin-top:20px;
}
 .footer-row .footer-date {
     margin-top:5px;
}
 .footer-section {
     background-color:#333333;
}
 .footer-row .footer1-icon li {
     display: inline-block;
     margin-left: 5px;
     margin-right: 5px;
}
 .footer-row .footer1-icon li:first-child {
     margin-left: 0;
}
 .footer-row .footer1-icon {
     margin-top: 30px;
}
 .footer-row h5 {
     margin-bottom:20px;
     color:#ffffff;
}
 .footer-row .footer1-address li {
     display: table-cell;
}
 .footer-row .footer2-address li:first-child, .footer-row .footer1-address li:first-child {
     padding-right: 10px;
}
 .footer-row .footer1-address li p, .footer-row .footer1-address li p a {
     color: #eeeeee;
     letter-spacing:0.5px;
}
 .footer-row .footer1-address-space {
     margin-bottom: 15px;
}
 .copyright-bg {
     padding-top:30px;
}
 .copyright-bg .copyright {
     border-top:1px solid #444444;
     padding-top:20px;
}
 .copyright p {
     color:#ffffff;
     letter-spacing:0.5px;
}
/*========================================== 19.FOOTER ENDS ===========================================*/
/*========================================== 20.MEDIA QUERIES START ===========================================*/
 @media only screen and (min-width: 250px) and (max-width: 767px) {
     .res-bottom-space30 {
         margin-bottom:30px;
    }
     .res-bottom-space40 {
         margin-bottom:40px;
    }    
     .copyright-bg .copyright {
         margin-left:15px;
         margin-right:15px;
    }
     .topbar.navbar-default .navbar-toggle .icon-bar {
         background-color: #f0a830;
    }
     .topbar.navbar-default .navbar-toggle:focus, .topbar.navbar-default .navbar-toggle:hover {
         background-color: transparent;
    }
     .topbar.navbar-default .navbar-toggle {
         border: none;
    }
     .topbar.navbar-default .navbar-toggle {
         margin-bottom: 15px;
         margin-top: 19px;
         margin-right: 0;
    }
     .navbar-nav.menu-ul > li > a {
         padding:10px 15px;
    }
     .menu-ul {
         margin:0 !important;
    }
     .topbar.navbar-default .navbar-collapse {
         border: 0;
         box-shadow:none;
    }
     .col-sm-12.col-md-8.about2-col-left {
         margin-left:15px;
         margin-right:15px;
    }
     .nav.navbar-nav.menu-ul {
         padding-bottom:10px;
    }
     .navigation-center .navbar-nav {
         display: block;
         text-align: left;
    }
     .cta-div, .header-bgimage-slider-3 .header-div, .header-bgimage-slider-2 .header-div, .header-bgimage-slider-1 .header-div {
         width:100%;
    }
     .gallery-div {
         /* padding:0 15px; */ /* Removed to keep gallery centered in small screen */
    }
     .counter-resSpace {
         margin-top:30px;
    }
     .about-points-1 li {
         width:100%;
    }
     .about-points-1 li:nth-child(2) {
         margin-top:20px;
    }
     .navbar-nav.menu-ul > li.menu {
         margin: 0px 0;
    }
     .popup-bg {
         width:80%;
    }
}
 @media only screen and (max-width: 991px) {
     .section-bottom-space20 {
         margin-bottom:20px;
    }
     .section-bottom-space30 {
         margin-bottom:30px;
    }
     .services2-col-topSpace {
         margin-top:30px;
    }
     .two-left-bgimage-1, .two-left-bgimage {
         width:100%;
    }
     .two-left-div {
         padding:30px 15px 80px 15px;
    }
     .two-left-div-1 {
         padding:30px 15px 80px 15px;
    }
     .two-left-bgimage-1 {
         position:relative;
    }
     .footer-common-space {
         margin-top:30px;
    }
}
 @media only screen and (max-width: 479px) {      
     .team-carousel {
         padding: 0 10px 30px 10px;
    }
     .cta-div h1, .header-bgimage-slider-3 .header-div h1, .header-bgimage-slider-2 .header-div h1, .header-bgimage-slider-1 .header-div h1 {
         font-size:30px;
         line-height:40px;
    }
     h2 {
         font-size: 28px;
         line-height: 38px;
    }
     h3 {
         font-size: 24px;
         line-height: 34px;
    }
     h4 {
         font-size: 18px;
         line-height: 28px;
    }
     .heading-padding {
         padding-bottom: 180px;
         padding-top: 180px;
    }
     .counter-value {
         font-size:30px;
         line-height:40px;
    }
     .packages-price p {
         font-size:30px;
    }
     .packages-price p span {
         font-size: 15px;
    }
     .header-bgimage-slider-3, .header-bgimage-slider-2, .header-bgimage-slider-1 {
         min-height:680px;
    }
     .services-row .services-col {
         width:100%;
    }
     .features-col.col-xs-6, .counter-col.col-xs-6 {
         width:100%;
    }
     .features-col:nth-child(2), .counter1-resSpace {
         margin-top:30px;
    }
}
 @media only screen and (max-width: 449px) {
     .fund-slider .popular-dest-div2 {
         margin:0 auto;
         text-align:center;
    }
     .fund-slider .popular-dest-div2 img{
         margin:0 auto;
    }
     .subscribe-form .subscribe-input {
         width:100%;
         margin-bottom:20px;
    }
}
 @media only screen and (max-width: 400px) {
     .gallery-div {
         margin:0;
         width:100% !important;
    }
     .gallery-div .gallery-col .gallery-image {
         width:100% !important;
    }
}
 @media only screen and (max-width: 599px) {
     .gallery-div .gallery-col {
         width:100%;
    }
     .gallery-div .gallery-col .gallery-image {
         width:390px;
         margin:0 auto;
    }
     .gallery-div .gallery-col.col-xs-6:nth-child(2), .gallery-div .gallery-col.col-xs-6:nth-child(1) {
         padding-bottom:4px;
    }
     .team-row .team_main_circle {
         width:200px;
         height:200px;
         left: 0;
         margin: 0 auto;
         right: 0;
    }
     .team-row .team_main_circle .team_profile_circle {
         bottom: -148px;
         height: 180px;
         left: 0px;
         right: 0;
         top: inherit;
         width: 180px;
    }
     .padtop30 {
         padding-bottom: 150px;
         padding-top: 30px;
    }
     .team_main_circle:hover .team_profile_circle{
         -webkit-transition: all 0.5s ease;
         -moz-transition: all 0.5s ease;
         -o-transition: all 0.5s ease;
         -ms-transition: all 0.5s ease;
         transition: all 0.5s ease;
         top: inherit;
         bottom: -120px;
    }
     .footer1-col.col-xs-6 {
         width:100%;
    }
     .footer1-col:nth-child(2) {
         margin-top:30px;
    }
     .footer-row .footer-date-ul-1 {
         padding-right: 10px;
    }
	.popup-btn-div button {
		width:100%;
	}
	.popup-btn-div button:nth-child(2){
		margin-top:20px;
	}
}
 @media only screen and (max-width:767px) and (max-height: 400px){
     .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
         max-height:200px;
    }
}
 @media only screen and (min-width: 600px) and (max-width:767px) {
   .gallery-resSpace1 {
         padding-top:4px;
    }
	.popup-btn-div button {
		width:47%;
	}
	.popup-btn-div button:nth-child(1) {
		margin-right:20px;
	}
}
 @media only screen and (min-width: 480px) and (max-width: 767px) {
     .book-form .col-xs-6 {
         margin-top:10px;
         margin-bottom:10px;
    }   
     .header-bgimage-slider-3, .header-bgimage-slider-2, .header-bgimage-slider-1 {
         min-height:780px;
    }
     .packages-col-1, .three-col-div, .causes-col .causes-div {
         margin:0 auto;
         width:380px;
    }
}
 @media only screen and (min-width: 400px) and (max-width: 479px) {
     .packages-col-1, .three-col-div, .causes-col .causes-div {
         margin:0 auto;
         width:380px;
    }
}
 @media only screen and (min-width: 768px) and (max-width: 991px) {
     .navbar-nav.menu-ul > li > a {
         font-size: 13px;
         padding: 0 9px;
    }
     .menu-div .btn {
         font-size:13px;
    }
     .popup-bg {
         width:60%;
    }
}
 @media only screen and (min-width: 992px) and (max-width: 1199px) {
     .subscribe-form .subscribe-input {
         width:100%;
         margin-bottom:20px;
    }
     .footer-row .post-img {
         display:table;
         float:none;
         padding-bottom:15px;
         padding-right:0;
    }
     .footer-row .post {
         display: table;
    }
     .footer-row .footer-date-ul-1 {
         padding-right: 10px;
    }
     .footer-row .footer1-icon li {
         margin-left:4px;
         margin-right:4px;
    }
     .team-row .team_main_circle .team_profile_circle {
         left:188px;
    }
     .two-left-col-1 {
         max-height: 424px;
         overflow: auto;
    }
     .two-left-bgimage-1 {
         height: 584px;
    }
     .two-left-col {
         max-height: 530px;
         overflow: auto;
    }
	.team-row .team_main_circle {
		left:-20px;
	}
}
 @media only screen and (max-width: 1280px) {
     .packages-col-1::before {
         transform: rotate(0);
    }
     .packages-col-1 {
         padding:30px 20px;
    }
     .row.packages-row {
         padding-top:0;
    }
     .packages-main {
         padding: 80px 0 80px;
    }
}
/*========================================== 20.MEDIA QUERIES END ===========================================*/
.isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }

#tac-content {
    /* visibility: hidden; */
    display:none;
}
#home-content {
    /* visibility: hidden; */
    display:none;
}