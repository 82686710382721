@import "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i";
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i";
body {
  color: #666;
  background: #fff;
  font-family: Open Sans, sans-serif, Arial, Verdana, Trebuchet MS;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

a {
  outline: none !important;
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

h1, h2, h3, h4, h5 {
  color: #333;
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 50px;
  line-height: 55px;
}

h2 {
  font-size: 35px;
  line-height: 40px;
}

h3 {
  font-size: 28px;
  line-height: 33px;
}

h4 {
  font-size: 20px;
  line-height: 25px;
}

h5 {
  font-size: 16px;
  line-height: 21px;
}

h6 {
  font-size: 15px;
  line-height: 20px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.section-padding {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section-1-bg {
  background-color: #fff;
}

.section-2-bg {
  background-color: #f2f2f2;
}

.black-bg {
  background-color: #333;
}

.heading-div-1 {
  padding-bottom: 40px;
}

.image-bottom-space {
  padding-bottom: 25px;
}

.image-bottom-space20 {
  padding-bottom: 20px;
}

.image-bottom-space1 {
  padding-bottom: 15px;
}

.image-top-space {
  padding-top: 25px;
}

.content-top-space {
  padding-top: 15px;
}

.content-top-space1 {
  padding-top: 10px;
}

.btn-top-space1 {
  margin-top: 10px;
}

.top-space {
  margin-top: 30px;
}

.top-space40 {
  margin-top: 40px;
}

.top-space20 {
  margin-top: 20px;
}

.top-space25 {
  margin-top: 25px;
}

.top-space15 {
  margin-top: 15px;
}

.top-space10 {
  margin-top: 10px;
}

.content-bottom-space {
  padding-bottom: 20px;
}

.bgimage-property {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-ul {
  margin-bottom: 0;
  padding-left: 0;
}

.content-ul li {
  list-style: none;
}

.underline {
  background-color: #fff;
  width: 60px;
  height: 2px;
  margin: 5px auto 0;
}

.underline-1 {
  background-color: #fff;
  width: 60px;
  height: 2px;
  margin: 5px 0 0;
}

.list-div-left, .list-div-body {
  display: table-cell;
}

.row-top-space {
  margin-top: 30px;
}

.no-padding {
  padding: 0;
}

.fixed-header {
  z-index: 99;
  border-radius: 0;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px #0000004d !important;
}

.navbar-right {
  margin-right: 0;
}

.navbar-default .navbar-nav > li > a:active, .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav.clearfix.fixed-header > li > a.active {
  transition: all 1.3s;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .open > a {
  box-shadow: none;
  background-image: none;
}

.navbar {
  border: none !important;
}

.navbar-brand, .navbar-nav > li > a {
  text-shadow: none;
}

.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
  background-color: #0000;
}

.logo-image {
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.topbar.navbar-default {
  background-color: #333;
  border-radius: 0;
  background-image: none !important;
  border: none !important;
  box-shadow: 0 0 5px #0000004d !important;
}

.navbar-nav.menu-ul {
  margin-top: 25px;
}

.navbar-nav.menu-ul > li.active > a.active, .navbar-nav.menu-ul > li > a.active, .topbar.navbar-default .navbar-nav > li > a:hover, .navbar-nav.menu-ul > li > a.active, .navbar-nav.menu-ul > .active > a, .navbar-nav.menu-ul > .active > a:hover, .navbar-default .navbar-nav.menu-ul > li > a:hover, .navbar-nav.menu-ul .pagescroll.active, .menu-ul .pagescroll:hover {
  color: #f0a830 !important;
}

.navbar-nav.menu-ul > a:focus, .topbar.navbar-default .navbar-nav > li > a:focus {
  color: #fff;
}

.navbar-nav.menu-ul > li > a {
  color: #fff;
  letter-spacing: .5px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 600;
}

.menu-div {
  margin: 15px 0 15px 10px;
}

.navbar-nav.menu-ul {
  margin: 0;
}

.navbar-nav.menu-ul > li.menu {
  margin: 25px 0;
}

.btn {
  cursor: pointer;
  letter-spacing: 1px;
  border: none;
  border-radius: 3px;
  outline: none;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
}

.btn-top-space {
  margin-top: 25px;
}

.btn1-div {
  display: inline-block;
  position: relative;
}

.btn.btn-1 {
  color: #fff;
  background-color: #f0a830;
  transition: all .7s;
}

.btn-1:hover {
  color: #f0a830;
  background: none;
}

.btn-1-white:hover:before, .btn-1-white:hover:after, .btn-1:hover:before, .btn-1:hover:after {
  width: 100%;
  transition: all .7s;
}

.btn-1:before, .btn-1:after {
  content: "";
  background: #f0a830;
  width: 0;
  height: 3px;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-1:after {
  top: inherit;
  left: inherit;
  bottom: 0;
  right: 0;
}

.btn-1-white {
  color: #f0a830;
  background-color: #fff;
  transition: all .7s;
}

.btn-1-white:hover {
  color: #fff;
  background: none;
}

.btn-1-white:before, .btn-1-white:after {
  content: "";
  background: #fff;
  width: 0;
  height: 3px;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-1-white:after {
  top: inherit;
  left: inherit;
  bottom: 0;
  right: 0;
}

.readmore {
  color: #f0a830;
  letter-spacing: 1px;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
}

.readmore:hover {
  color: #f0a830;
}

.readmore-white {
  color: #fff;
  letter-spacing: 1px;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
}

.readmore-white:hover {
  color: #fff;
}

.header-bgimage-slider-1 {
  background-image: url("slider-1.d99c7d42.jpg");
}

.header-bgimage-slider-2 {
  background-image: url("slider-2.c5b182ea.jpg");
}

.header-bgimage-slider-3 {
  background-image: url("slider-3.c2606f11.jpg");
}

.heading-padding {
  padding-top: 230px;
  padding-bottom: 230px;
}

.header-bgimage-slider-1 .header-div {
  margin: 0 auto;
}

.header-bgimage-slider-1 .header-div h1 {
  color: #fff;
  letter-spacing: 1px;
  font-size: 55px;
  font-weight: 700;
  line-height: 65px;
}

.header-bgimage-slider-1 .header-div p {
  color: #fff;
  width: 80%;
  margin: 0 auto;
  font-weight: 600;
}

.header-bgimage-slider-2 .header-div {
  width: 80%;
  margin: 20px auto;
}

.header-bgimage-slider-2 .header-div h1 {
  color: #333;
  letter-spacing: 1px;
  font-size: 55px;
  font-weight: 700;
  line-height: 65px;
}

.header-bgimage-slider-2 .header-div p {
  color: #666;
  width: 80%;
  font-weight: 600;
}

.header-bgimage-slider-3 .header-div {
  width: 80%;
  margin: 32px auto;
}

.header-bgimage-slider-3 .header-div h1 {
  color: #333;
  letter-spacing: 1px;
  font-size: 55px;
  font-weight: 700;
  line-height: 65px;
}

.header-bgimage-slider-3 .header-div p {
  color: #666;
  width: 80%;
  margin-left: auto;
  font-weight: 600;
}

.header-slider.owl-theme .owl-controls .owl-page span {
  background-color: #eb593b;
}

.header-slider.owl-theme .owl-controls {
  text-align: center;
  z-index: 1;
  margin: 0 auto;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.book-now-form {
  background-color: #f0a830;
  padding: 30px;
  box-shadow: 0 19px 38px #0000000d, 0 -19px 38px #00000029;
}

.form-head h3 {
  color: #fff;
  margin-top: 20px;
}

.form-head {
  background-color: #333;
  padding: 30px 20px;
}

.book-form .form-input {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 0;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.book-form .form-div label {
  color: #c00;
  font-weight: 600;
}

.book-form .failMessage {
  color: #c00;
}

.book-form .form-div {
  margin-bottom: 20px;
}

.book-form .form-textarea {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 0;
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.form-textarea::-webkit-input-placeholder {
  color: #fff;
}

.form-input::-webkit-input-placeholder {
  color: #fff;
}

:is(.form-textarea:-ms-input-placeholder, .form-input:-ms-input-placeholder) {
  color: #fff;
}

.form-textarea::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.form-input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

:is(.form-textarea:-moz-placeholder, .form-input:-moz-placeholder) {
  color: #fff;
  opacity: 1;
}

.services-bg-4, .services-bg-1 {
  background-color: #f0a830;
  transition: all .7s;
}

.services-bg-2 {
  background-color: #eb593b;
  transition: all .7s;
}

.services-bg-3 {
  background-color: #333;
  transition: all .7s;
}

.services-div {
  padding: 80px 15px;
}

.services-bg-4 p, .services-bg-4 h4, .services-bg-1 p, .services-bg-1 h4, .services-bg-2 p, .services-bg-2 h4, .services-bg-3 p, .services-bg-3 h4 {
  color: #fff;
}

.services-main .containter-fluid, .services-main .services-col {
  padding: 0;
}

.services-bg-1:hover {
  background-image: url("380x450x1.b37f62d4.jpg");
}

.services-bg-2:hover {
  background-image: url("380x450x2.b37f62d4.jpg");
}

.services-bg-3:hover {
  background-image: url("380x450x3.b37f62d4.jpg");
}

.services-bg-4:hover {
  background-image: url("380x450x4.b37f62d4.jpg");
}

.causes-col img {
  border-bottom: 5px solid #f0a830;
}

.pie-title-center {
  text-align: center;
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
}

.pie-title-center canvas {
  background-color: #f0a830;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.pie-value {
  color: #fff;
  height: 40px;
  margin-top: -25px;
  font-size: 13px;
  font-weight: 600;
  line-height: 40px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.causes-img {
  position: relative;
}

.causes-goal {
  margin-top: 40px;
}

.fund-goal li, .causes-goal li {
  letter-spacing: 1px;
  font-weight: 600;
  display: inline-block;
}

.fund-goal li {
  color: #fff;
}

.fund-goal li:first-child, .causes-goal li:first-child {
  margin-right: 20px;
}

.causes-goal li:first-child span {
  color: #999;
}

.causes-goal li:last-child span {
  color: #f0a830;
}

.fund-goal li:first-child span {
  color: #f2f2f2;
}

.fund-goal li:last-child span {
  color: #f0a830;
}

.fund-goal {
  background-color: #444;
  padding: 10px;
}

.causes-div {
  box-shadow: 0 12px 15px -6px #0000004d;
}

.causes-content {
  padding: 0 15px 30px;
}

.fund-div {
  background-color: #eb593b;
}

.fund-content {
  padding: 0 15px 30px;
}

.fund-div p {
  color: #fff;
}

.fund-div h4 {
  color: #fff;
  background-color: #333;
  padding: 10px 15px;
}

.about4-div img {
  vertical-align: middle;
  padding-right: 10px;
}

.list-img {
  float: left;
  width: 65px;
}

.list-img, .list-content {
  display: table-cell;
}

.two-left-bgimage {
  background-image: url("1000x730x1.76619bf7.jpg");
  width: 50%;
  height: 695px;
}

.two-left-div {
  padding: 80px 30px;
}

.two-left-div h3 a {
  color: #333;
}

.two-left-bgimage-1 {
  background-image: url("1000x730x2.76619bf7.jpg");
  width: 50%;
  height: 594px;
  position: absolute;
  right: 0;
}

.two-left-div-1 {
  padding: 80px 30px 80px 0;
}

.two-left-div-1 h3 a {
  color: #333;
}

.two-left-right-bg {
  position: relative;
}

.three-col-div {
  -o-transition: all .5s ease;
  transition: all .5s;
  box-shadow: 0 12px 18px -6px #0006;
}

.three-col-div:hover {
  box-shadow: 0 0 9px -6px #0006;
}

.three-col-content {
  padding: 0 15px 30px;
}

.about-points-1 li {
  float: left;
  width: 50%;
}

.features-div-1 .list-img {
  width: 64px;
  margin-right: 20px;
  display: inline-block;
  position: relative;
}

.features-div-1 .list-img img {
  border-radius: 50%;
}

.features-div-1 .list-img:after {
  box-sizing: content-box;
  content: "";
  opacity: 0;
  pointer-events: none;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding: 4px;
  transition: transform .2s, opacity .2s;
  position: absolute;
  top: -4px;
  left: -4px;
  transform: scale(.8);
  box-shadow: 0 0 0 3px #eb593b;
}

.features-col:hover .features-div-1 .list-img:after {
  opacity: 1;
  transform: scale(1);
}

#fade {
  z-index: 1001;
  -moz-opacity: .7;
  opacity: .7;
  filter: alpha(opacity= 70);
  background-color: #000;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0%;
  left: 0%;
}

#success_btn, #cancel_btn {
  z-index: 99999;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  width: 35%;
  margin: 0 auto;
  padding: 7px 10px 30px;
  display: none;
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  box-shadow: 0 12px 18px -6px #000000b3;
}

.cancel-head-pad {
  padding: 30px 10px 15px;
}

.popup-bg {
  background-image: url("600x450.549da2ba.jpg");
  width: 40%;
}

.popup-bg input {
  color: #fff;
  background-color: #ffffff4d;
  border: 0;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.popup-bg select {
  color: #fff;
  background-color: #ffffff4d;
  border: 0;
  width: 100%;
  height: 40px;
  padding-left: 15px;
}

.popup-bg option {
  color: #333 !important;
}

.p-white {
  color: #fff;
  margin-top: 28px;
  font-weight: 600;
}

.popup-bg h2 {
  color: #fff;
}

.popup-form {
  width: 85%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.popup-bg .close {
  color: #fff;
  opacity: 1;
}

.close, .close:hover {
  opacity: 1;
}

.popup-bg label {
  display: none !important;
}

.popup-bg select.error, .popup-bg input.error {
  background-color: #f006 !important;
}

.popup-bg input::-webkit-input-placeholder {
  color: #fff;
  text-transform: none;
}

.popup-bg input::-moz-placeholder {
  color: #fff;
  text-transform: none;
  opacity: 1;
}

.popup-bg input::-moz-placeholder {
  color: #fff;
  text-transform: none;
}

.popup-bg input:-ms-input-placeholder {
  text-transform: none;
  color: #fff !important;
}

.cta-bgimage {
  background-image: url("1920x700x1.f18d655f.jpg");
  padding: 180px 0;
}

.cta-div {
  width: 80%;
  margin: 0 auto;
}

.cta-div h1 {
  color: #fff;
  letter-spacing: 1px;
  font-size: 55px;
  font-weight: 700;
  line-height: 65px;
}

.cta-div p {
  color: #fff;
  width: 80%;
  margin: 0 auto;
  font-weight: 600;
}

.client-logo .item {
  text-align: center;
  margin: 0 auto;
}

.fund-slider.owl-carousel.owl-theme .owl-controls .owl-pagination {
  display: none;
}

.fund-slider.owl-theme .owl-controls .owl-buttons div {
  opacity: 1;
  background: #f0a830;
  border-radius: 0;
  margin: 0 3px;
  padding: 3px 5px;
}

.fund-slider.owl-theme .owl-controls .owl-buttons {
  position: absolute;
  top: -50px;
  right: 0;
}

.fund-slider .icon-left:before {
  color: #fff;
  content: "";
  font-family: Fontawesome;
  font-size: 12px;
}

.fund-slider .icon-right:before {
  color: #fff;
  content: "";
  font-family: Fontawesome;
  font-size: 12px;
}

.fund-slider {
  margin-top: 40px;
}

.fund-slider .item {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.gallery-div .gallery-image {
  position: relative;
  overflow: hidden;
}

.gallery-div .overlay {
  opacity: 0;
  background: #eb593b99;
  border-radius: 3px;
  width: 0;
  height: 0;
  margin: auto;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.gallery-div .gallery-image span {
  color: #fff;
  text-align: center;
  opacity: 0;
  height: 30px;
  margin: auto;
  font-size: 25px;
  line-height: 30px;
  transition: transform .5s, opacity .7s;
  position: absolute;
  inset: 0;
  transform: scale(0);
}

.gallery-div .gallery-image:hover .overlay {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.gallery-div .gallery-image:hover span {
  opacity: 1;
  transition: transform .5s, opacity 1.3s;
  transform: scale(1);
}

.gallery-div .gallery-image img {
  border-radius: 3px;
}

.gallery-div.popup-gallery .col-sm-4 {
  padding-left: 2px;
  padding-right: 2px;
}

.gallery-div.popup-gallery .gallery-space1 {
  padding-top: 4px;
}

.counter-bgimage {
  background-image: url("1920x700x2.f18d655f.jpg");
  padding: 150px 0;
}

.counter-div img {
  border-radius: 50%;
}

.counter-value {
  color: #fff;
  letter-spacing: 1px;
  margin-top: 20px;
  font-size: 50px;
  font-weight: 600;
  line-height: 55px;
}

.counter-title {
  color: #fff;
  letter-spacing: 1px;
  font-weight: 600;
}

.counter-img {
  display: inline-block;
  position: relative;
}

.counter-img:after {
  box-sizing: content-box;
  content: "";
  opacity: 0;
  pointer-events: none;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding: 4px;
  transition: transform .2s, opacity .2s;
  position: absolute;
  top: -4px;
  left: -4px;
  transform: scale(.8);
  box-shadow: 0 0 0 3px #eb593b;
}

.counter-col:hover .counter-img:after {
  opacity: 1;
  transform: scale(1);
}

.packages-col-1 {
  padding: 30px 50px;
  position: relative;
}

.packages-border, .packages-price {
  z-index: 2;
  position: relative;
}

.packages-main {
  padding: 80px 0;
}

.packages-price p {
  color: #333;
  font-size: 40px;
  font-weight: 600;
}

.packages-price p span {
  font-size: 20px;
}

.packages-bg:before {
  z-index: 1;
  background-color: #eb593b;
}

.packages-bg .packages-price p, .packages-bg .packages-content p, .packages-bg h4 {
  color: #fff;
}

.packages-col-1 {
  -o-transition: all .5s ease;
  transition: all .5s;
}

.packages-col-1:hover {
  z-index: 2;
  transform: scale(1.1);
}

.packages-bg-1 {
  background-color: #fff;
}

.packages-bg {
  background-color: #eb593b;
}

.clients-bgimage {
  background-image: url("1920x700x5.f18d655f.jpg");
}

.map-frame {
  border: 0;
  width: 100%;
  height: 562px;
}

.testimonial .item {
  margin-bottom: 10px;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.testimonial.owl-theme .owl-controls .owl-page span {
  background-color: #f0a830;
  border-radius: 0;
}

.testimonial .owl-pagination {
  margin-top: 30px;
}

.testimonial .testimonial-div {
  border: 1px solid #f0a830;
  padding: 120px 30px 50px;
}

.testimonial .testimonial-div > img {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.testimonial .testimonial-name {
  color: #f2f2f2;
  letter-spacing: .5px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.testimonial .testimonial-name span {
  color: #f0a830;
  letter-spacing: .5px;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600;
}

.testimonial-bg {
  background-image: url("1920x700x4.f18d655f.jpg");
}

.testimonial-para {
  color: #eee;
}

.testimonial-bg h2 {
  color: #fff;
}

.testimonial {
  padding-top: 30px;
}

.news-ul li:nth-child(2) {
  background-color: #eb593b;
  width: 70px;
  padding: 10px;
}

.news-ul li:nth-child(2) p a {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.news-ul li:nth-child(2) p span {
  font-size: 30px;
}

.news-div-1 .list-img {
  -o-transition: all .5s ease;
  width: 70px;
  transition: all .5s;
  box-shadow: 0 12px 18px -6px #0006;
}

.news-div-1 .list-content {
  padding-left: 20px;
}

.news-col h4 a {
  color: #333;
}

.news-col:hover .list-img {
  box-shadow: 0 0 9px -6px #0006;
}

.team-carousel {
  padding: 0 40px;
}

.team-carousel .owl-pagination {
  display: none;
}

.team-carousel .carousel-control.left:before {
  color: #f0a830;
  content: "";
  background: #fff;
  border: 4px solid #f0a830;
  border-radius: 23px;
  width: 40px;
  height: 40px;
  margin-top: 30px;
  font-family: Fontawesome;
  font-size: 12px;
  line-height: 34px;
  position: absolute;
  top: 50px;
  left: 0;
}

.team-carousel .carousel-control.right:before {
  color: #f0a830;
  content: "";
  background: #fff;
  border: 4px solid #f0a830;
  border-radius: 23px;
  width: 40px;
  height: 40px;
  margin-top: 30px;
  padding-left: 3px;
  font-family: Fontawesome;
  font-size: 12px;
  line-height: 34px;
  position: absolute;
  top: 50px;
  right: -12px !important;
}

.team-carousel.owl-theme .owl-controls .owl-buttons div {
  background-color: #0000;
}

.team-carousel .carousel-indicators {
  margin-right: -19px;
  top: auto;
  bottom: 0;
  right: 50%;
}

.team-carousel .carousel-indicators li {
  background: silver;
}

.team-carousel .carousel-indicators .active {
  background: #333;
}

.team_profile_circle img {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  position: absolute;
  top: 0;
  left: 0;
}

.team-row .team_main_circle {
  background: #f2f2f2;
  border: 5px solid #f0a830;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  display: table;
  position: relative;
}

.team-row .team_main_circle .team_main_circle_contain {
  vertical-align: middle;
  text-align: center;
  letter-spacing: .5px;
  font-size: 18px;
  font-weight: 600;
  display: table-cell;
}

.team-row .team_main_circle .team_main_circle_contain p:nth-child(2) {
  color: #333;
  letter-spacing: .5px;
  font-size: 14px;
}

.team-row .team_main_circle .team_main_circle_contain p {
  color: #eb593b;
  width: 120px;
  margin: 0 auto;
}

.team-row .team_main_circle .team_profile_circle {
  border: 5px solid #f0a830;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  position: absolute;
  top: -25px;
  left: 200px;
}

.padtop30 {
  padding-top: 90px;
  padding-bottom: 50px;
}

.team-icon li {
  margin: 3px;
  display: inline-block;
}

.team-bg {
  background-color: #333;
}

.team-bg h2 {
  color: #fff;
}

.team_main_circle:hover .team_profile_circle {
  -o-transition: all .5s ease;
  transition: all .5s;
  top: -70px;
}

#mc_embed_signup {
  clear: left;
}

#mc-embedded-subscribe {
  margin: 0 !important;
}

#ResultMsg {
  text-align: center;
}

.SuccessMsg {
  color: #3c3;
}

.FailureMsg {
  color: red;
}

.messageDiv {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  display: table;
}

.SuccessMsg, .FailureMsg, .sucessMessage, .failMessage {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  display: none;
}

.sucessMessage {
  color: green;
}

.failMessage {
  color: red;
}

.message > .SuccessMsg {
  color: green;
}

.place_error::-webkit-input-placeholder {
  color: red;
  text-transform: none;
}

.place_error::placeholder {
  color: red;
  text-transform: none;
}

.place_error:-ms-input-placeholder {
  text-transform: none;
  color: red !important;
}

.subscribe-form .subscribe-input {
  color: #fff;
  background-color: #0000;
  border: 1px solid #eee;
  border-radius: 3px;
  width: 100%;
  max-width: 100%;
  height: 35px;
  margin-bottom: 20px;
  padding: 0 15px;
  display: inline-block;
}

.subscribe-form .subscribe-form {
  margin: 30px auto 0;
}

.subscribe2-btn {
  display: inline-block;
}

.footer-row .footer1-col .links li a {
  color: #eee;
  letter-spacing: .5px;
  line-height: 30px;
}

.footer-row .footer1-col .links li {
  border-bottom: 1px solid #666;
  margin-top: 5px;
  padding-bottom: 5px;
  list-style: none;
  position: relative;
}

.footer-row .footer1-col .links li:before {
  color: #fff;
  content: "";
  padding-right: 15px;
  font-family: Fontawesome;
  font-size: 14px;
}

.footer-row .post-img {
  float: left;
  opacity: 1;
  padding-right: 15px;
  display: table-cell;
  overflow: hidden;
}

.footer-row .post {
  display: table-cell;
}

.footer-row .post p a {
  color: #eee;
  letter-spacing: .5px;
  font-weight: 600;
}

.footer-row .footer-date ul {
  display: inline-block;
}

.footer-row .footer-date-ul li {
  vertical-align: middle;
  display: inline-block;
}

.footer-row .footer-date-ul li:first-child {
  padding-right: 5px;
}

.footer-row .footer-date-ul-1 {
  padding-bottom: 10px;
}

.footer-row .popular-space {
  margin-top: 20px;
}

.footer-row .footer-date {
  margin-top: 5px;
}

.footer-section {
  background-color: #333;
}

.footer-row .footer1-icon li {
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.footer-row .footer1-icon li:first-child {
  margin-left: 0;
}

.footer-row .footer1-icon {
  margin-top: 30px;
}

.footer-row h5 {
  color: #fff;
  margin-bottom: 20px;
}

.footer-row .footer1-address li {
  display: table-cell;
}

.footer-row .footer2-address li:first-child, .footer-row .footer1-address li:first-child {
  padding-right: 10px;
}

.footer-row .footer1-address li p, .footer-row .footer1-address li p a {
  color: #eee;
  letter-spacing: .5px;
}

.footer-row .footer1-address-space {
  margin-bottom: 15px;
}

.copyright-bg {
  padding-top: 30px;
}

.copyright-bg .copyright {
  border-top: 1px solid #444;
  padding-top: 20px;
}

.copyright p {
  color: #fff;
  letter-spacing: .5px;
}

@media only screen and (width >= 250px) and (width <= 767px) {
  .res-bottom-space30 {
    margin-bottom: 30px;
  }

  .res-bottom-space40 {
    margin-bottom: 40px;
  }

  .copyright-bg .copyright {
    margin-left: 15px;
    margin-right: 15px;
  }

  .topbar.navbar-default .navbar-toggle .icon-bar {
    background-color: #f0a830;
  }

  .topbar.navbar-default .navbar-toggle:focus, .topbar.navbar-default .navbar-toggle:hover {
    background-color: #0000;
  }

  .topbar.navbar-default .navbar-toggle {
    border: none;
    margin-top: 19px;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .navbar-nav.menu-ul > li > a {
    padding: 10px 15px;
  }

  .menu-ul {
    margin: 0 !important;
  }

  .topbar.navbar-default .navbar-collapse {
    box-shadow: none;
    border: 0;
  }

  .col-sm-12.col-md-8.about2-col-left {
    margin-left: 15px;
    margin-right: 15px;
  }

  .nav.navbar-nav.menu-ul {
    padding-bottom: 10px;
  }

  .navigation-center .navbar-nav {
    text-align: left;
    display: block;
  }

  .cta-div, .header-bgimage-slider-3 .header-div, .header-bgimage-slider-2 .header-div, .header-bgimage-slider-1 .header-div {
    width: 100%;
  }

  .counter-resSpace {
    margin-top: 30px;
  }

  .about-points-1 li {
    width: 100%;
  }

  .about-points-1 li:nth-child(2) {
    margin-top: 20px;
  }

  .navbar-nav.menu-ul > li.menu {
    margin: 0;
  }

  .popup-bg {
    width: 80%;
  }
}

@media only screen and (width <= 991px) {
  .section-bottom-space20 {
    margin-bottom: 20px;
  }

  .section-bottom-space30 {
    margin-bottom: 30px;
  }

  .services2-col-topSpace {
    margin-top: 30px;
  }

  .two-left-bgimage-1, .two-left-bgimage {
    width: 100%;
  }

  .two-left-div, .two-left-div-1 {
    padding: 30px 15px 80px;
  }

  .two-left-bgimage-1 {
    position: relative;
  }

  .footer-common-space {
    margin-top: 30px;
  }
}

@media only screen and (width <= 479px) {
  .team-carousel {
    padding: 0 10px 30px;
  }

  .cta-div h1, .header-bgimage-slider-3 .header-div h1, .header-bgimage-slider-2 .header-div h1, .header-bgimage-slider-1 .header-div h1 {
    font-size: 30px;
    line-height: 40px;
  }

  h2 {
    font-size: 28px;
    line-height: 38px;
  }

  h3 {
    font-size: 24px;
    line-height: 34px;
  }

  h4 {
    font-size: 18px;
    line-height: 28px;
  }

  .heading-padding {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .counter-value {
    font-size: 30px;
    line-height: 40px;
  }

  .packages-price p {
    font-size: 30px;
  }

  .packages-price p span {
    font-size: 15px;
  }

  .header-bgimage-slider-3, .header-bgimage-slider-2, .header-bgimage-slider-1 {
    min-height: 680px;
  }

  .services-row .services-col, .features-col.col-xs-6, .counter-col.col-xs-6 {
    width: 100%;
  }

  .features-col:nth-child(2), .counter1-resSpace {
    margin-top: 30px;
  }
}

@media only screen and (width <= 449px) {
  .fund-slider .popular-dest-div2 {
    text-align: center;
    margin: 0 auto;
  }

  .fund-slider .popular-dest-div2 img {
    margin: 0 auto;
  }

  .subscribe-form .subscribe-input {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (width <= 400px) {
  .gallery-div {
    margin: 0;
    width: 100% !important;
  }

  .gallery-div .gallery-col .gallery-image {
    width: 100% !important;
  }
}

@media only screen and (width <= 599px) {
  .gallery-div .gallery-col {
    width: 100%;
  }

  .gallery-div .gallery-col .gallery-image {
    width: 390px;
    margin: 0 auto;
  }

  .gallery-div .gallery-col.col-xs-6:nth-child(2), .gallery-div .gallery-col.col-xs-6:first-child {
    padding-bottom: 4px;
  }

  .team-row .team_main_circle {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .team-row .team_main_circle .team_profile_circle {
    bottom: -148px;
    left: 0;
    right: 0;
    top: inherit;
    width: 180px;
    height: 180px;
  }

  .padtop30 {
    padding-top: 30px;
    padding-bottom: 150px;
  }

  .team_main_circle:hover .team_profile_circle {
    -o-transition: all .5s ease;
    top: inherit;
    transition: all .5s;
    bottom: -120px;
  }

  .footer1-col.col-xs-6 {
    width: 100%;
  }

  .footer1-col:nth-child(2) {
    margin-top: 30px;
  }

  .footer-row .footer-date-ul-1 {
    padding-right: 10px;
  }

  .popup-btn-div button {
    width: 100%;
  }

  .popup-btn-div button:nth-child(2) {
    margin-top: 20px;
  }
}

@media only screen and (width <= 767px) and (height <= 400px) {
  .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
    max-height: 200px;
  }
}

@media only screen and (width >= 600px) and (width <= 767px) {
  .gallery-resSpace1 {
    padding-top: 4px;
  }

  .popup-btn-div button {
    width: 47%;
  }

  .popup-btn-div button:first-child {
    margin-right: 20px;
  }
}

@media only screen and (width >= 480px) and (width <= 767px) {
  .book-form .col-xs-6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .header-bgimage-slider-3, .header-bgimage-slider-2, .header-bgimage-slider-1 {
    min-height: 780px;
  }

  .packages-col-1, .three-col-div, .causes-col .causes-div {
    width: 380px;
    margin: 0 auto;
  }
}

@media only screen and (width >= 400px) and (width <= 479px) {
  .packages-col-1, .three-col-div, .causes-col .causes-div {
    width: 380px;
    margin: 0 auto;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .navbar-nav.menu-ul > li > a {
    padding: 0 9px;
    font-size: 13px;
  }

  .menu-div .btn {
    font-size: 13px;
  }

  .popup-bg {
    width: 60%;
  }
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .subscribe-form .subscribe-input {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer-row .post-img {
    float: none;
    padding-bottom: 15px;
    padding-right: 0;
    display: table;
  }

  .footer-row .post {
    display: table;
  }

  .footer-row .footer-date-ul-1 {
    padding-right: 10px;
  }

  .footer-row .footer1-icon li {
    margin-left: 4px;
    margin-right: 4px;
  }

  .team-row .team_main_circle .team_profile_circle {
    left: 188px;
  }

  .two-left-col-1 {
    max-height: 424px;
    overflow: auto;
  }

  .two-left-bgimage-1 {
    height: 584px;
  }

  .two-left-col {
    max-height: 530px;
    overflow: auto;
  }

  .team-row .team_main_circle {
    left: -20px;
  }
}

@media only screen and (width <= 1280px) {
  .packages-col-1:before {
    transform: rotate(0);
  }

  .packages-col-1 {
    padding: 30px 20px;
  }

  .row.packages-row {
    padding-top: 0;
  }

  .packages-main {
    padding: 80px 0;
  }
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: .5;
  text-decoration: none;
}

#tac-content, #home-content {
  display: none;
}
/*# sourceMappingURL=index.57374a2f.css.map */
